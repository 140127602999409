<template>
  <en-dialog :model-value="modelValue" width="80%" title="收款单" @close="$emit('update:model-value', false)">
    <template #operation>
      <button-ajax link :method="operation.export.click">导出</button-ajax>
    </template>

    <table-dynamic
      :height="600"
      code="RCTFD"
      :data="table.data"
      :loading="table.loading"
      :paging="table.paging"
      :method="table.get"
      pagination
      :config="table.config"
    >
    </table-dynamic>
  </en-dialog>
</template>

<script lang="ts">
export default factory({
  props: {
    modelValue: Boolean
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean'
  },

  config: {
    children: {
      operation: {
        children: {
          export: {
            async click() {
              return this.ajax('GET /enocloud/settlement/receipt/export', this.table.$ajaxParams)
            }
          }
        }
      },
      tabs: {
        active: ''
      },
      table: {
        ajax: {
          get: {
            action: 'GET /enocloud/settlement/receipt/query',
            data: 'array',
            loading: true,
            pagination: true
          }
        },
        config: {
          SERIAL_NO: { header: { filter: { type: 'text', field: 'serialNo' } } },
          CELLPHONE: { header: { filter: { type: 'text', field: 'contactCellphone' } } },
          CONTACT_NAME: { header: { filter: { type: 'text', field: 'contactName' } } },

          PAYMENT_METHOD: {
            header: {
              filter: {
                type: 'select',
                field: 'paymentMethods',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/hint/:hintTypeStr',
                    params: (params) => (params.paths = ['PAMTMTD'])
                  },
                  props: { label: 'name', value: 'name' }
                }
              }
            }
          },
          RECEIPT_DATETIME: {
            header: { filter: { type: 'date', field: ['businessStartDate', 'businessEndDate'], props: { type: 'daterange' } } }
          },
          PLATE_NO: { header: { filter: { type: 'text', field: 'plateNo' } } }
        }
      }
    }
  },

  mounted() {
    this.table.get()
  }
})
</script>
